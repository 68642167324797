import Vue from 'vue'
import Vuex from 'vuex'
import { $api } from 'bh-mod'
import settings from './settings'
import {setProp, deleteProp,arrToObj} from 'bh-mod'
// setProp(state,['units',data.id],data)
let tld = window.location.href.includes('.com')? 'com' : 'dev'
let siteURL = window.location.href.includes('localhost')? 'http://localhost:3001' : `https://services.bildhive.${tld}`
console.log('SITE URL', siteURL)
siteURL = `https://services.bildhive.${tld}`
Vue.use(Vuex)

export default {
    state: {
        pageSections:[],
        filterPublished:false,
        builder:{
            top:0,
            dynamic:true,
            currentSection:'',
            currPage:{},
            currProject:{},
            currentPageSlug:'',
        },
        datas:{},
        fontList:[],
        allSettings:{},
        filter:'completed',
        modelTags:{},
        tempEmail:{},
        projects:{},
        chooseColor:{
            show:false,
            data:{}
        },
        sectionModal:{
            init:false,
            show:false,
            cb:null,
            restriction:'none',
            replace:false
        },
        siteURL,
        cson:[],
        broadcastModal:{
            show:false,
            id:'',
        },
        emailVariable:{
            show:false,
            fields:[]
        },
        currentProject:{},
        sections:{},
        senders:[],
        loadingPage:false,
        newStoreTime:Date.now(),
        getStarted: false,
        templates:{},
        drafts:{},
        broadcasts:{},
        dom:{w:document.body.clientWidth,h:document.body.clientHeight},
        addContent:{
            show:false,
            page:{},
            isNew:false
        },
        templateDrawer: {
            show:false,
            type:'add',
            site:'',
            data:{}
        },
        modal:{
            show:false,
            title:'',
        }

    },
    mutations: {
        UPDATE_PROJECT(state,project){
            let newProjects = JSON.parse(JSON.stringify(state.projects))
            newProjects[project.id] = project
            state.projects = newProjects
            console.log('UPDATED PROJECTS', state.projects)
        },
        SET_SETTINGS(state, data) {
            if (data.userApp == null) {
                data.userApp = {
                    options:{
                        seenIntro:false,
                    }
                }
            }
            state.allSettings = data
        },
        SET_SECTION: (state,section) => state.sections[section.id] = section,
        SET_SECTIONS: (state,sections) => state.sections = {...state.sections,...arrToObj(sections)},
        CHOOSE_COLOR: (state,data) => {
            state.chooseColor = {
                show:true,
                type:data.type,
                color:data.color
            }
        },
        SET_EMAIL_VARIABLES: (state,data) => state.appDataVariable.fields = data,
        SHOW_EMAIL_VARIABLE: state => state.appDataVariable.show = true,
        CLOSE_EMAIL_VARIABLE: state => state.appDataVariable.show = false,
        SET_BROADCAST: (state,data) => state.broadcastModal = {show:true,data},
        CLOSE_BROADCAST: (state,data) => state.broadcastModal = {show:false,data:{}},
        VIEWPORT: (state,w) => state.dom = w,
        SHOW_ADD_CONTENT: (state,{page,isNew}) => state.addContent={show: true,page,isNew },
        CLOSE_ADD_CONTENT: state => state.addContent={show: false,page:{},isNew:false },
        SET_CSON: (state,cson) => state.cson = cson,
        LOAD_PAGE: (state,status = !state.loadingPage) => state.loadingPage = status,
        SET_PROP: (state,{where,what,del = false,update = false}) => {
            console.log('SET PROP RAN => WHERE', where)
            console.log('SET PROP RAN => WHAT', what)
            if (del) return deleteProp(state,where)
            if (typeof where === 'string') where = where.split('.')
            state.newStoreTime = Date.now()
            setProp(state,where,what)
        },
        CLOSE_DRAWERS: state => {
            state.templateDrawer = {
                show:false,
                type:'',
                id:'',
                data:null
            }
            state.broadcastModal = {show:false,data:{}}
        },
        CLOSE_MODALS: (state) => {
            state.statusModal = {type:'', show:false}
            state.formModal = {show:false,type:''}
            state.chooseColor = {show:false,type:'',color:''}
        },
        SHOW_ADD_TEMPLATE: (state)=> {
            state.templateDrawer = {
                show:true,
                type:'add',
                id:'',
                data:null
            }
        },
        SHOW_EDIT_TEMPLATE: (state,data)=> {
            state.templateDrawer = {
                show:true,
                type:'edit',
                id:'',
                data
            }
        },
        SET_APPDATA(state, data) {
            if (!data) data = {}
            let {projects= []} = data

            state.projects = arrToObj(projects)

        },
        GET_STARTED: state => {
            state.getStarted = true
        },
    },
    actions: {
        SET_APPDATA: ({commit},data) => commit('SET_APPDATA',data),

        MESSAGE: ({state},data) => {
            document.querySelector('iframe').contentWindow.postMessage(data, state.siteURL)
        }

    },
    getters:{
        completed: state => {
            return Object.values(state.broadcasts).filter(x => x.sent).map(x => ({...x,key:x.id}))
        },
        scheduled: state => {
            return Object.values(state.broadcasts).filter(x => !x.sent).map(x => ({...x,key:x.id}))
        },
        emailApi: (state, getters, rootState) => `/email-sections/${rootState.instance.id}`,
        touchPages: state => {

        },
        pageSections: state => {
            let project = state.builder.currProject || {}

            let sections = []

            if (!project.pages) return []

            let result = []


            project.pages.forEach(page => {

                let {sections,header,footer} = page

                if (header && header.id) result.push(header)
                if (footer && footer.id) result.push(footer)
                if (sections && sections.length) {
                    sections.forEach(sec => {
                        result.push(sec)
                    })
                }

            })

            return result
        },
        pageSectionsOptions: (state,getters) => {

            let result = getters.pageSections.map(item => ({
                label:item.name,
                value:item.slug || item.id
            }))
            return [
                {
                    label:'Not linked',
                    value:'',

                },
                ...result
            ]
        },
        fonts:() => [
                {
                    name:'Arial',
                    fontText:`Arial, sans-serif`,
                },
                {
                    name:'Verdana',
                    fontText:`Verdana, sans-serif`,
                },
                {
                    "name":"Georgia",
                    "fontText":"Georgia, serif",
                },
                {
                    name:'Times New Roman',
                    fontText:`'Times New Roman', serif`,
                },
                {
                    "name":"Courier",
                    "fontText":"Courier, sans-serif"
                },
                {
                    name:'Abril Fatface',
                    google:'Abril+Fatface',
                    fontText:`'Abril Fatface', Georgia, serif`
                },
                {
                    name:'Alegreya',
                    google:'Alegreya:wght@400;700',
                    fontText:`'Alegreya', serif`,
                },
                {
                    name:'Alegreya Sans',
                    fontText:`'Alegreya Sans', sans-serif`,
                    google:`Alegreya+Sans:wght@400;700`,
                },
                {
                    name: "Alice",
                    google: "Alice",
                    fontText: "Alice,Helvetica, Arial, sans-serif"
                },
                {
                    name: "Roboto",
                    google: "Roboto:400,700",
                    fontText: "Roboto, Helvetica, Arial, sans-serif"
                },
                {
                    name: "Montserrat",
                    google: "Montserrat:wght@400;700",
                    fontText: "Montserrat, Helvetica, sans-serif"
                },
                {
                    name: "Lato",
                    google: "Lato:wght@400;700",
                    fontText: `'Lato', sans-serif`
                },
                // 'Arial',
                // 'Arimo',
                // 'Arvo',
                // 'Catamaran',
                // 'Comic Sans',
                // 'Courier New',
                // 'Della Respira',
                // 'DM Sans',
                // 'Georgia',
                // 'Gilda Display',
                // 'Helvetica',
                // 'Lato',
                // 'Lora',
                // 'Lucida',
                // 'Marcellus',
                // 'Merriweather',
                // 'Merriweather Sans',
                // 'Nanum Gothic Coding',
                // 'Neuton',
                // 'Noticia Text',
                // 'Noto Sans',
                // 'Open Sans',
                // 'Playfair Display',
                // 'Raleway',
                // 'Recursive',
                // 'Roboto',
                // 'Source Sans Pro',
                // 'Source Code Pro',
                // 'Space Mono',
                // 'Syncopate',
                // 'Tahoma',
                // 'Times New Roman',
                // 'Trebuchet MS',
                // 'Verdana',
            ]
    }
}
