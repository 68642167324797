<template>
  <div id="app">
    <BHLoading :show="$store.state.loading" />
    <MediaSelector />
    <LibrarySelector />
    <localization class="locl"></localization>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Localization from 'bh-mod/localization'
import BHLoading from 'bh-mod/components/common/Loading'
import MediaSelector from 'bh-mod/components/file/MediaSelector'
import LibrarySelector from 'bh-mod/components/file/LibrarySelector'



export default {
  name: 'app',
  components: { Localization,MediaSelector,BHLoading,LibrarySelector },
  computed: {
    ...mapGetters(['user']),
  },
  watch: {
    '$route'(to, from) {
      const query = Object.assign({}, to.query)
      this.$store.commit('SETUP_URL_SETTINGS', query)
    },
  },
  mounted(){
      window.addEventListener('resize', (e) => {
        this.$store.commit('VIEWPORT',{w:document.body.clientWidth, h:document.body.clientHeight})
      })
  }
}
</script>
<style lang="scss">
  #app{
    min-height: 100vh;
  }
  .ant-drawer-body{
    padding:0;
    margin:0;
  }
  iframe{
    border:0;
  }
  .ant-drawer-header-no-title [aria-label="Close"]{
    background:white;
  }
  .builder-edit-icon-circle{
    width: 20px;
    font-size: 12px;
    padding: 5px;
    border-radius: 50%;
    background: var(--light-purple);
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 3px rgba(100,100,100,0.4);
    color:var(--dark-gray);
    &:hover{
      background:var(--purple);
      color:white;
    }
  }
</style>
