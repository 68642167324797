<template>
    <SubBarGlobal :currentCrumb="currentCrumb">
      <PageButtons />
    </SubBarGlobal>
</template>
<script>
  const search = {
          leadScore:{
            type:'_gte',
            value:1
          },
          firstName:{
            type:'_contains',
            value:'',
          },
          lastName:{
            type:'_contains',
            value:'',
          },
          email:{
            type:'_contains',
            value:'',
          },
          tagI:{
            type:'any',
            value:[]
          },
          tagE:{
            type:'any',
            value:[]
          }
  }

  import {getAppIcon, getInitial} from 'bh-mod'
  import SubBarGlobal from 'bh-mod/components/layout/SubBarGlobal'
  import PageButtons from 'bh-mod/components/layout/SubBarGlobal/PageButtons'

  export default {
    components:{SubBarGlobal,PageButtons},
    data(){
      return {
        loadSearch:false,
        search: JSON.parse(JSON.stringify(search))
      }
    },
    watch:{
      showSearch(val){
        if (val){

          let OBJ = JSON.parse(JSON.stringify(search))
          let storedSearch = JSON.parse(JSON.stringify(this.$store.state.appData.searchOBJ))
          if (storedSearch.leadScore && storedSearch.leadScore.hasOwnProperty('value')){
            storedSearch.leadScore.value = parseInt(storedSearch.leadScore.value)
          }

          Object.entries(storedSearch).forEach( ([key,value]) => {
            OBJ[key] = value
          })
          this.search = OBJ
        } else {
          this.loadSearch = false
          this.search = JSON.parse(JSON.stringify(search))
        }
      }
    },
    computed:{
      currentCrumb(){
        if (this.$route.meta.crumbName) return false
        if (this.$route.path !== '/') return 'Unknown Page'
        return this.$store.state.appData.filterPublished ? 'Published Projects' : 'All Projects'
      },
      pageButtons(){
        return this.$store.state.pageButtons
      },
      crumbItems(){
        if (this.$store.state.crumbs && this.$store.state.crumbs.length) this.$store.state.crumbs
        else if (this.$route.meta.crumbName) return [{name:this.$route.meta.crumbName}]
        else [{name:'unknown page'}]
      },
      appInitial(){
        let appinit
        if (this.theApp.name == 'Models') {
          appinit="Contract Builder (HMC)"
        }
        return appinit
      },
      theApp(){
           return this.$store.state.theApp || {}
      },
      showSearch(){
        // return this.$store.state.appData.showSearch
      },
      tags(){
        return this.$store.state.appData.tags
      },
      instance(){
        return this.$store.state.instance
      },
    },
    methods:{
      getAppIcon, 
      getInitial,
      searchContacts(){
        this.loadSearch = true
        this.$store.commit('SET_SEARCH',this.search)
        this.$store.commit('SET_NEW_SEARCH_TIME')
        this.$store.commit('TOGGLE_SEARCH')
      }
    },
    created(){
      
    }
  }
</script>

<style lang="scss">
  .ant-form-horizontal.search-horizontal{
    label{
    }
    .ant-form-item-control-wrapper{
      flex:1;
    }
    .ant-form-item{
      display:flex;
    }
    .ant-form-item-label{
      min-width:100px;
    }
  }
</style>
